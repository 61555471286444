import Vuex from "vuex";
import 'es6-promise/auto'
import Vue from "vue";
import {AXIOS} from "./http-commons";

Vue.use(Vuex);
// csProperties from App.vue
export const store = new Vuex.Store({
    state: {
      csProperties: {
        version: 'NotSet',
        applicationName: 'CallSystem',
        defaultNotifyCustomer: true,
        clientName: 'Loading',
        "quickServe.preselectedNotes": '',
        "quickServe.callVisits": "true",
        "quickServe.addAndCall": "true",
        "quickServe.createShowNotes": "true",
        "quickServe.createShowTicket": "true",
        "homeScreen.forceBranchSelect" : "true",
      },
      auth: {
        isLoggedIn: false,
        username: null,
        jwtToken: null,
        authLevels: null
      },
      userState: {
        branch: {
          selectedBranchId: -1
        },
        currentlyServing: {
          eventType : null,
          visit: {
            customer: {firstName: '', lastName: ''},
            ticket: '',
          },
        },
      },
    },
    mutations: {
      addProperties(state, list) {
        state.csProperties = list;
      },
      addAuth(state, authObj) {
        state.auth = authObj;
      },
      setSelectedBranchId(state, branchId) {
        state.userState.branch.selectedBranchId = branchId
      },
      setCurrentlyServing(state, currentlyServing) {
        state.userState.currentlyServing = currentlyServing;
      }
    },
    getters: {
      csProperties: state => {
        return state.csProperties
      },
      auth: state => {
        return state.auth
      },
      getBranchID: state => {
        return state.userState.branch.selectedBranchId
      },
      getCurrentlyServing: state => {
        return state.userState.currentlyServing
      },
    },
    actions: {
      loadProperties({commit}) {
        AXIOS
          .get('/rest/properties')
          .then(r => r.data)
          .then(props => {
            commit('addProperties', props);
          });
      },
      addAuth({commit}, addAuthObj) {
        commit('addAuth', addAuthObj);
      },
      setSelectedBranchId({commit}, selectedBranchId) {
        commit('setSelectedBranchId', selectedBranchId);
      },
      setCurrentlyServing({commit}, currentlyServing) {
        commit('setCurrentlyServing', currentlyServing);
      }
    }
  })
;
