<template>
  <div>
    <div class="">
      <card type='card-danger' class="text-center" style="">
        <h1 class="card-title">Ticket Lookup</h1>
        <h4 class="card-subtitle">Use this page to quickly look at the applications statistics</h4>
      </card>
    </div>
    <div class="row">
      <div class="col-md-4">
        <card title="Lookup by Ticket" class="card-animation-on-hover"
              sub-title="Select a date and ticket ID to show all the visits">
          <div class="row">

            <div class="col-md-6">
              <base-input label="Ticket"
                          type="text"
                          placeholder="Enter ticket"
                          v-model="ticket">
              </base-input>
            </div>

            <div class="col-md-6">
              <base-input>
                <label>Select a date</label>
                <el-date-picker v-model="datePicker"
                                type="date"
                                placeholder="Select a date"
                                format="dd-MM-yyyy">
                </el-date-picker>
              </base-input>
            </div>
          </div>
          <base-button type="primary" class="" round block @click="lookupTicket">Search for visits by ticket
          </base-button>
        </card>
      </div>


      <div class="col-md-4">
        <card title="Lookup by Phone number"
              class="card-animation-on-hover"
              sub-title="Select a date and phone number to show all the visits">
          <div class="row">

            <div class="col-md-6">
              <label>Phone Number</label>
              <vue-tel-input id="phoneNumberLookup"
                             v-model="phoneNumber"
                             :disabledFetchingCountry=true
                             :preferredCountries="[ 'gb', 'ie','us']"
                             :defaultCountry="$t('vuePhoneNumber.defaultCountry')">
              </vue-tel-input>
            </div>

            <div class="col-md-6">
              <base-input>
                <label>Select a date</label>
                <el-date-picker v-model="datePicker"
                                type="date"
                                placeholder="Select a date"
                                format="dd-MM-yyyy">
                </el-date-picker>
              </base-input>
            </div>
          </div>
          <base-button type="primary" class="" round block @click="lookupPhone">Search for visits by phone number
          </base-button>
        </card>


      </div>

      <div class="col-md-4">
        <card title="Lookup by day" class="card-animation-on-hover"
              sub-title="Select a date to show all the visits for the specified day">
          <div class="row">


            <div class="col-md-12">
              <base-input>
                <label>Select a date</label>
                <el-date-picker v-model="datePicker"
                                type="date"
                                placeholder="Select a date"
                                format="dd-MM-yyyy">
                </el-date-picker>
              </base-input>
            </div>
          </div>
          <base-button type="primary" class="" round block @click="lookupTicketByDay">Search for visits by day
          </base-button>
        </card>
      </div>
    </div>


    <div v-for="(visit, index) in orderedVisits" class="col-md-9 ml-auto mr-auto">
      <card class="card-animation-on-hover" title=" " :sub-title="renderIndex(index)">
        <!-- Not in a row -->
        <div class="row col-md-12">
          <div class="col-md-4">
            <p>Ticket: {{visit.ticket}}</p>
            <p>Create Time: {{visit.createTime}}</p>
            <p>Branch: {{visit.branchName}}</p>
            <p>Queue: {{visit.queueName}}</p>
          </div>
          <div class="col-md-4">
            <div v-if="visit.customer">
              <p v-if="visit.customer.firstName !== ''">{{$t('visitLookup.firstName')}}:
                {{visit.customer.firstName}}</p>
              <p v-if="visit.customer.lastName !== ''">{{$t('visitLookup.lastName')}}: {{visit.customer.lastName}}</p>
              <p v-if="visit.customer.phoneNumber !== ''">Phone Number: {{visit.customer.phoneNumber}}</p>
            </div>
            <p v-if="visit.parsedJson.notes && visit.parsedJson.notes !== ''">{{$t('visitLookup.notes')}}:
              {{visit.parsedJson.notes}}</p>
            <p v-if="visit.callStation">Call Station: {{visit.callStation.name}}</p>
            <p v-if="visit.createStation">Create Station: {{visit.createStation.name}}</p>
          </div>

          <card class="bg-info col-md-4">
            <h4 class="card-title">Event types:</h4>
            <div class="" v-for="event in visit.events">
              <p v-if="event.visitEventType">{{event.visitEventType.visitEventTypeName.toUpperCase()}} -
                {{event.eventDate}}</p>
            </div>

          </card>

        </div>

      </card>

    </div>
  </div>
</template>

<script>
  import {DatePicker, TimeSelect} from 'element-ui'
  import {AXIOS} from "../../config/http-commons";
  import VueTelInput from 'vue-tel-input'
  import 'vue-tel-input/dist/vue-tel-input.css';

  export default {
    name: "TicketLookup",
    components: {
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      VueTelInput
    },
    mounted() {
      let span = document.getElementById('phoneNumberLookup');
      let input = span.children[1];
      input.className = 'form-control';
    },
    methods: {
      lookupTicket() {
        if (this.ticket === "" || this.datePicker === "") {
          this.$notify({type: 'danger', message: 'Please populate ticket and date'})
          return
        }
        AXIOS.get('/rest/stat/lookup/date/' + this.formatDate() + '/ticket/' + this.ticket).then(r => {
          this.selectedVisits = r.data;
          if (this.selectedVisits.length === 0) {
            this.$notify({type: 'warning', message: 'No tickets found'})
          }
        });
      },
      lookupPhone() {
        if (this.phoneNumber === "" || this.datePicker === "") {
          this.$notify({type: 'danger', message: 'Please populate phone number and date'});
          return;
        }
        AXIOS.get('/rest/stat/lookup/date/' + this.formatDate() + '/phone/' + this.phoneNumber).then(r => {
          this.selectedVisits = r.data
          if (this.selectedVisits.length === 0) {
            this.$notify({type: 'warning', message: 'No tickets found'})
          }
        });
      },
      lookupTicketByDay() {
        if (this.datePicker === "") {
          this.$notify({type: 'danger', message: 'Please populate date'})
          return
        }
        AXIOS.get('/rest/stat/lookup/date/' + this.formatDate()).then(r => {
          this.selectedVisits = r.data
          if (this.selectedVisits.length === 0) {
            this.$notify({type: 'warning', message: 'No tickets found'})
          }
        });
      },
      formatDate() {
        let date = this.datePicker;
        let day = date.getUTCDate();
        day = "00" + day;
        day = day.slice(-2);
        let month = (date.getUTCMonth() + 1);
        month = "00" + month;
        month = month.slice(-2);
        let year = date.getUTCFullYear();
        return "" + year + month + day;
      },
      renderIndex(index) {
        return index + 1 + " out of " + this.orderedVisits.length
      }
    },
    data() {
      return {
        datePicker: new Date(),
        selectedVisits: [],
        ticket: '',
        phoneNumber: ''
      }
    },
    computed: {
      orderedVisits: function () {
        let list = this.selectedVisits;
        list.sort(function (a, b) {
          return a.createTime < b.createTime
        });
        return list;
      },
    }
  }
</script>

<style scoped>

</style>
