<template>
  <!-- Controls the sidebar, and the hides/shows the items
    mainJS is used by the router and navigating between-->
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button/>
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <template slot-scope="props" slot="links">
        <sidebar-item
          :link="{
            name: $t('sidebar.homePage'),
            icon: 'tim-icons icon-bus-front-12',
            path: '/homePage'
          }"></sidebar-item>

        <sidebar-item
          :link="{
            name: 'Serving',
            icon: 'tim-icons icon-laptop'
          }">
          <sidebar-item v-if="isAllowedToSee('ROLE_QUICKSERVE')"
                        :link="{ name: $t('sidebar.quickServe'), path: '/quickServe' }"
          ></sidebar-item>
          <sidebar-item v-if="isAllowedToSee('ROLE_RECEPTION')"
                        :link="{ name: $t('sidebar.reception'), path: '/receptionPage' }"
          ></sidebar-item>
          <sidebar-item v-if="isAllowedToSee('ROLE_COUNTER')"
                        :link="{ name: $t('sidebar.workstation'), path: '/workstationPage' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
            name: $t('sidebar.ticketLookup'),
            path: '/ticketLookup'
          }"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="isAllowedToSee('ROLE_EASYSTATS')"
                      :link="{
            name: 'Statistics',
            icon: 'tim-icons icon-chart-pie-36'
          }">
          <sidebar-item
            :link="{
            name: $t('sidebar.statPage'),
            path: '/statPage'
          }"></sidebar-item>
          <sidebar-item
            :link="{
            name: $t('sidebar.statBranchPage'),
            path: '/statBranchPage'
          }"></sidebar-item>
          <sidebar-item
            :link="{
            name: $t('sidebar.statQueuePage'),
            path: '/statQueuePage'
          }"></sidebar-item>
        
        </sidebar-item>

        <!-- TODO REMOVE FALSE  TO SHOW CALENDAR-->
        <sidebar-item v-if="isAllowedToSee('ROLE_CALENDAR') && false"
                      :link="{
            name: $t('sidebar.calendar'),
            icon: 'tim-icons icon-time-alarm',
            path: '/calendar'
          }"
        ></sidebar-item>

        <sidebar-item v-if="isAllowedToSee('ROLE_ADMIN')" :link="{
            name: 'Admin',
            icon: 'tim-icons icon-molecule-40'
          }">
          <sidebar-item
            :link="{ name: $t('sidebar.branchAdmin'), path: '/branchAdmin' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.servicePointAdmin'), path: '/servicePointAdmin' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.createStationAdmin'), path: '/createStationAdmin' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.queueAdmin'), path: '/queueAdmin' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.smsLookup'), path: '/smsLookup' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="isAllowedToSee('ROLE_SUPERADMIN')"
                      :link="{
            name: 'Super Admin',
            icon: 'tim-icons icon-molecule-40'
          }">
          <sidebar-item
            :link="{ name: $t('sidebar.applicationAdmin'), path: '/applicationAdmin' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.userAdmin'), path: '/userAdmin' }"
          ></sidebar-item>

        </sidebar-item>

      </template>
    </side-bar>
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import DashboardContent from './Content.vue';
  import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
  import {SlideYDownTransition, ZoomCenterTransition} from 'vue2-transitions';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      SidebarFixedToggleButton,
      DashboardContent,
      SlideYDownTransition,
      ZoomCenterTransition
    },
    data() {
      return {
        sidebarBackground: 'vue'//vue|blue|orange|green|red|primary
      };
    },
    computed: {},
    mounted :function () {
      this.initScrollbar()
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      isAllowedToSee(role) {
        let usersAuth = this.$store.getters.auth.authLevels;
        if (usersAuth.indexOf('ROLE_SUPERADMIN') >= 0) {
          // If superadmin ALLOW ALL
          return true;
        }

        let ret = usersAuth.indexOf(role);
        // debugger
        return ret >= 0;
      },
      initScrollbar() {
        let docClasses = document.body.classList;
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          // if we are on windows OS we activate the perfectScrollbar function
          initScrollbar('sidebar');
          initScrollbar('sidebar-wrapper');

          docClasses.add('perfect-scrollbar-on');
        } else {
          docClasses.add('perfect-scrollbar-off');
        }
      }
    }
  };
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
