import axios from 'axios'

export const AXIOS = axios.create({
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  })
;
