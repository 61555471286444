import { render, staticRenderFns } from "./StatBranchPage.vue?vue&type=template&id=b242538c&scoped=true&"
import script from "./StatBranchPage.vue?vue&type=script&lang=js&"
export * from "./StatBranchPage.vue?vue&type=script&lang=js&"
import style0 from "./StatBranchPage.vue?vue&type=style&index=0&id=b242538c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b242538c",
  null
  
)

export default component.exports