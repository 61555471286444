<template>
  <div>

    <el-select class="select-primary"
               style="width: 100%"
               placeholder="Select a Queue"
               v-model="queues.selected"
               v-on:change="emitNewQueueSelected"
               value-key="logicId">
      <el-option value=-2 key=-2 class="select-warning" label="Please select a branch" ></el-option>
      <el-option v-for="option in queues.api"
                 class="select-info"
                 :value="option"
                 :label="option.name"
                 :key="option.logicId">
      </el-option>
    </el-select>
  </div>
</template>



<script>
  import {AXIOS} from '../../config/http-commons'
  import {Option, Select} from 'element-ui'

  export default {
    name: "QueueSelect",
    data() {
      return {
        queues: {selected: this.defaultCreateQueueName, api: []}
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    props: {
      branchId: {type: Number, required: true},
      defaultCreateQueueName: {type: String, default: ''} 
    },
    methods: {
      emitNewQueueSelected: function () {
        console.log('emitNewQueueSelected new Queue Selected:'+ this)
        this.$emit('emitNewQueueSelected', this.queues.selected)
      }
      },
      created() {
        AXIOS.get('/rest/branch/' + this.branchId)
          .then((response) => {
            this.$data.queues.api = response.data.queues;
          });
      },
    watch: {
      branchId: function (newV, oldV) {
        AXIOS.get('/rest/branch/' + newV)
          .then((response) => {
            this.$data.queues.api = response.data.queues;
          });
      },
      defaultCreateQueueName()
      {
        console.log('Queue Component defaultCreateQueue set')
        this.queues.selected = this.defaultCreateQueueName        
      },
      newQueueSelected()
      {
        console.log('Queue Component newCreateQueueSelected')       
      }
    },
  }
</script>

<style scoped>

</style>
