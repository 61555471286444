<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline">
        <sidebar-toggle-button/>
      </div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#pablo">{{ routeName }}</a>
    </div>
    <BranchSelect></BranchSelect>
    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">

      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item">
        <a slot="title"
           class="dropdown-toggle nav-link"
           data-toggle="dropdown"
           aria-expanded="true">
          <div class=" d-none d-lg-block d-xl-block"></div> <!-- add notification class for dot-->
          <i class="tim-icons icon-sound-wave"></i>
          <p class="d-lg-none">New Notifications</p>
        </a>
        <div v-for="noti in notificationsA">
          <li class="nav-link">
            <a :href="noti.href" class="nav-item dropdown-item">{{noti.text}}</a>
          </li>
        </div>
      </base-dropdown>
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        menu-classes="dropdown-navbar">
        <a slot="title"
           href="/#"
           class="dropdown-toggle nav-link"
           data-toggle="dropdown"
           aria-expanded="true">
          <div class="photo bg-white"><img src="img/icon-vue.png"/></div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none">Log out</p>
        </a>
        <li class="nav-link">
          <a @click="toggleLight" class="nav-item dropdown-item">Toggle Dark Mode</a>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a class="nav-item dropdown-item" @click="logout">Log out</a>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
  import {CollapseTransition} from 'vue2-transitions';
  import {BaseNav, Modal} from '@/components';
  import SidebarToggleButton from './SidebarToggleButton';
  import BranchSelect from '../../components/custom/BranchSelect';

  export default {
    components: {
      SidebarToggleButton,
      CollapseTransition,
      BaseNav,
      Modal,
      BranchSelect
    },
    computed: {
      routeName() {
        const {name} = this.$route;
        return this.capitalizeFirstLetter(name);
      },
      isRTL() {
        return this.$rtl.isRTL;
      }
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: '',
        notificationsA: [
          {
            href: '#',
            text: 'No new Notifications'
          }
        ]
      };
    },
    methods: {
      toggleLight() {
        let b = document.body.classList.toggle('white-content');
        console.log("Add to storage!")
        window.localStorage.setItem('lightMode', b.toString())
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      logout() {
        window.localStorage.removeItem("auth");
        window.location.href = '/logout'
      }
    },
  };
</script>
<style scoped>
  .top-navbar {
    top: 0px;
  }
</style>
