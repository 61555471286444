<template>
  <div>
    <p v-for="queue in processedData">{{queue.name}}: {{queue.count}}</p>
  </div>
</template>

<script>
  import {AXIOS} from '../../../config/http-commons'

  export default {
    name: "QueueOverview",
    data: function () {
      return {
        queues: [],
        liveData: {},
        timeout: null,
      }
    },
    props: {
      branchId: {type: Number, required: true},
    },
    created() {
      this.getLiveData();
      this.timeouts();

    },
    methods: {
      getLiveData() {
        if (this.branchId !== -1) {
          AXIOS.get('/rest/branch/' + this.branchId).then(resp => {
            this.queues = resp.data.queues;
            this.refreshVisits();
          });
        }
      },
      refreshVisits() {
        AXIOS.get('/rest/media/branch/' + this.branchId + '/visits').then(resp => {
          this.liveData = resp.data;
        });
      },
      timeouts() {
        this.refreshVisits();
        this.timeout = setTimeout(this.timeouts, 30000);
      }

    },
    computed: {
      processedData() {
        let queues = [];
        this.queues.forEach(value => {
          if (this.liveData[value.logicId]) {
            queues.push({name: value.name, count: this.liveData[value.logicId].length});
          } else {
            queues.push({name: value.name, count: 0});
          }
        });

        return queues;
      }
    },
    beforeDestroy() {
      clearTimeout(this.timeout)
    },
    watch: {
      branchId() {
        this.getLiveData();
      }
    }

  }
</script>

<style scoped>

</style>
