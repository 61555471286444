<template>
  <div>
    <label style="">Select a Createstation</label>
    <el-select class="select-primary"
               style="width: 100%; padding-bottom: 10px;"
               placeholder="Select a Createstation"
               v-model="createStations.selected"
               v-on:change="emitCreateStationObj"
               value-key="logicId">
      <el-option :value=-1 :key=-1 class="select-warning" label="Please select a create station"></el-option>
      <el-option v-for="option in createStations.api"
                 class="select-info"
                 :value="option"
                 :label="option.name"
                 :key="option.logicId">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  import {AXIOS} from '../../config/http-commons'
  import {Option, Select} from 'element-ui'

  export default {
    name: "CallStationSelect",
    data() {
      return {
        createStations: {selected: this.defaultCreateStationName, api: []} 
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    props: {
      branchId: {type: Number, required: true},
      defaultCreateStationName: {type: String, default: ''} 
    },
    methods: {
      emitCreateStationObj: function () {
        console.log('emit CreateStation:')
        console.log(this.createStations.selected)
        this.$emit('emitCreateStationObj', this.createStations.selected);
      }
      },
      created() {
        AXIOS.get('/rest/createstations/branch/' + this.branchId)
          .then((response) => {
            this.createStations.api = response.data;
          });
      },
    watch: {
      branchId: function (newV, oldV) {
        AXIOS.get('/rest/createstations/branch/' + newV)
          .then((response) => {
            this.createStations.api = response.data;
          });
      },
      defaultCreateStationName()
      {
        console.log('CreateStation Component defaultCreateStation set')
        this.createStations.selected = this.defaultCreateStationName        
      },
      newCreateStationSelected()
      {
        console.log('CreateStation Component newCreateStationSelected')       
      }
    }
  }
</script>

<style scoped>

</style>
