<template>
  <div>

    <el-select class="select-primary"
               style="width: 100%"
               placeholder="Select a Service Point"
               v-model="callStations.selected"
               v-on:change="selectCallStationId">
      <el-option class="select-warning" value=-2 label="Please select a branch" key=-2></el-option>
      <el-option v-for="option in callStations.api"
                 class="select-info"
                 :value="option.logicId"
                 :label="option.name"
                 :key="option.logicId">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  import {AXIOS} from '../../config/http-commons'
  import {Option, Select} from 'element-ui'

  export default {
    name: "ServicePointSelect",
    data() {
      return {
        callStations: {selected: '', api: []}
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    props: {
      branchId: {type: Number, required: true},
    },
    methods:{
      selectCallStationId(newCallStation) {
        this.$emit('newCallStationSelected', newCallStation)
      }
    },
    created() {
      AXIOS.get('/rest/callstations/branch/' + this.branchId)
        .then((response) => {
          this.callStations.api = response.data;
        });
    },
    watch: {
      branchId: function (newV, oldV) {
        AXIOS.get('/rest/callstations/branch/' + newV)
          .then((response) => {
            this.callStations.api = response.data;
          });
      }
    }
  }
</script>

<style scoped>

</style>
