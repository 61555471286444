<template>
  <div>

    <div class="row">
      <card type='card-danger' class="text-center" style="">
        <h1 class="card-title">Quick Serve</h1>
        <h4 class="card-text">Use this page to quickly create and call visits from the <b>'Quick Serve Queue'</b></h4>
      </card>
    </div>
    <div class="row">
      <div v-bind:class="fullScreenCreate">
        <card class="card-animation-on-hover" title="Create Visits">
          <h5 class="card-subtitle mb-2 text-muted">Use this panel to create visits.</h5>
          <div class="card-body">
            <div class="row" v-if="createHideTicket">
              <div class="col-md-9">
                <base-input v-model="txtTicketToCreate"
                            label="Ticket Number"
                            type="number"
                            :disabled=chkGenerateTicket
                            placeholder="Enter Ticket">
                </base-input>
              </div>
              <!-- UI hack -->
              <div class="col-md-3">
                <br>
                <label>Generate Ticket</label>
                <base-switch v-model="chkGenerateTicket">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </base-switch>
              </div>
            </div>

            <div class="row">
              <div class="col-md-9">
                <label>Visit Phone Number</label>
                <vue-tel-input id="quickServerVueTelInput"
                               v-model="txtPhoneNumToCreate"
                               :disabledFetchingCountry=true
                               :preferredCountries="[ 'gb', 'ie','us']"
                               :defaultCountry="$t('vuePhoneNumber.defaultCountry')">
                </vue-tel-input>
              </div>
              <div class="col-md-3">
                <br>
                <label>Notify Customer</label>
                <base-switch v-model="chkNotifyCustomer">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </base-switch>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <base-input v-model="txtFirstNameToCreate"
                            :label="$t('quickServe.firstName')"
                            type="text"
                            :placeholder="$t('quickServe.firstNamePlaceHolder')">
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input v-model="txtLastNameToCreate"
                            :label="$t('quickServe.lastName')"
                            type="text"
                            :placeholder="$t('quickServe.lastNamePlaceHolder')">
                </base-input>
              </div>
            </div>
            <div class="row" v-if="createHideNotes">
              <base-input v-model="txtNotesToCreate"
                          :label="$t('quickServe.notes')"
                          class="col-md-12"
                          type="text"
                          :placeholder="$t('quickServe.notesPlaceholder')">
              </base-input>
            </div>
            <base-button @click="createVisit" class="animation-on-hover" block type="primary">Create Visit</base-button>
          </div>
        </card>

      </div>
    </div>
    <div class="row">
      <card class="col-md-8 ml-auto mr-auto text-center card-animation-on-hover" title="Last Served">
        <div class="card-body row">
          <p class="col-md-6">Ticket number: {{lastCalledTicket}}</p>
          <p class="col-md-6"
             v-if="$store.getters.getCurrentlyServing.visit.customer && $store.getters.getCurrentlyServing.visit.customer.firstName !== ''">
            {{$t('currentlyServed.customerName')}}: {{lastCalledName}}</p>
        </div>
      </card>
    </div>
    <div class="row">
      <card class="">
        <live-ticket-table :queue-id="-1"></live-ticket-table>
      </card>
    </div>
  </div>
</template>

<script>
  import {AXIOS} from '../../config/http-commons';
  import LiveTicketTable from '../Components/custom/LiveTicketTable';
  import {EventBus} from "../../config/Events";
  import {BaseSwitch} from "../../components";
  import VueTelInput from 'vue-tel-input'
  import 'vue-tel-input/dist/vue-tel-input.css';

  export default {
    name: "QuickServe",
    components: {
      LiveTicketTable,
      BaseSwitch,
      VueTelInput
    },
    data: function () {
      return {
        chkGenerateTicket: true,
        serving: '',
        txtTicketToCreate: null,
        txtPhoneNumToCreate: '',
        txtFirstNameToCreate: '',
        txtLastNameToCreate: '',
        chkNotifyCustomer: true,
        txtNotesToCreate: '',
        selectedBranchId: this.$store.getters.getBranchID,
        numNotifyAtPos: 5,
      }
    },
    computed: {
      fullScreenCreate() {
        return 'col-md-12';
      },
      createHideTicket() {
        return this.$store.getters.csProperties['quickServe.createShowTicket'] === 'true'
      },
      createHideNotes() {
        return this.$store.getters.csProperties['quickServe.createShowNotes'] === 'true'
      },
      lastCalledTicket() {
        let ticket = 'Not currently serving';
        let wse = this.$store.getters.getCurrentlyServing;
        if (wse.visit.ticket) {
          ticket = wse.visit.ticket
        }

        if (wse.eventType === 'END') {
          ticket = 'Not currently serving';
        }

        // let lastTicketCall = this.$store.getters.getCurrentlyServing.visit.ticket === '' && this.$store.getters.getCurrentlyServing.eventType !== 'END' ?
        //   'Not Currently Serving' : this.$store.getters.getCurrentlyServing.visit.ticket;
        return ticket;
      },
      lastCalledName() {

        let wse = this.$store.getters.getCurrentlyServing;

        let customer = 'No name found';
        if (wse.visit.customer && wse.visit.customer.firstName && wse.visit.customer.firstName !== '') {
          customer = wse.visit.customer.firstName;
        }
        if (wse.eventType === 'END') {
          customer = 'No name found';
        }
        return customer
      }
    },
    methods: {
      clearInputs() {
        this.txtTicketToCreate = '';
        this.txtPhoneNumToCreate = '';
        this.txtFirstNameToCreate = '';
        this.txtLastNameToCreate = '';
        this.txtNotesToCreate = '';
      },
      createVisit() {
        let text = this.txtTicketToCreate;
        let phoneNumber = this.txtPhoneNumToCreate;
        let fname = this.txtFirstNameToCreate;
        let lname = this.txtLastNameToCreate;
        let notify = this.chkNotifyCustomer;
        //TODO
        //   if ($('#forceCustomerName').html() == "true") {
        let json = JSON.stringify({
          "ticket": text,
          customJsonProperties: JSON.stringify({
            "notes": this.txtNotesToCreate,
            "notify": notify,
          }),
          customer: {
            firstName: fname,
            lastName: lname,
            phoneNumber: phoneNumber,
            // emailAddress: ''
          },
        });
        AXIOS.post("/rest/quickServe/branch/" + this.$data.selectedBranchId + "/add", json).then(response => {
          if (!this.chkGenerateTicket)
            this.txtTicketToCreate++; // increment to make life better
          this.$notify({type: 'success', message: "Visit '" + response.data.visit.ticket + "' Created"});
          this.clearInputs()
        }).catch(error => {
          if (error.response.data.errorCodeMessage) {
            this.$notify({type: 'danger', message: error.response.data.errorCodeMessage});
          } else {
            this.$notify({type: 'danger', message: 'Visit cannot be created'});
          }
        });
      },
    },
    beforeDestroy() {
      if (this.selectedBranchId != -1) {
        this.$stompClient.unsubscribe('/topic/add/' + this.selectedBranchId);
        this.$stompClient.unsubscribe('/topic/call/' + this.selectedBranchId);
      } else {
        this.tableData = [];
      }
    },
    mounted() {
      EventBus.$on('selectBranchLogicId', selectBranchLogicId => {
        this.selectedBranchId = selectBranchLogicId;
      });
      let span = document.getElementById('quickServerVueTelInput');
      let input = span.children[1]
      input.className = 'form-control';
    }
  };
</script>

<style scoped>
</style>
