<template>
  <div>
    <card class="text-center">
      <h1 class="card-title">Branch Admin</h1>
      <h4 class="card-subtitle">Use this page to manage branches</h4>
    </card>
    <div class="row">
      <div class="col-lg-7">
        <card title="Current Branches">
          <el-table :data="tableData">
            <el-table-column min-width="50" type="index"></el-table-column>
            <el-table-column min-width="150" prop="branchName" label="Name"></el-table-column>
            <el-table-column min-width="70" prop="logicId" label="Branch ID"></el-table-column>
            <el-table-column min-width="100" prop="branchTimeZone" label="Timezone"></el-table-column>

            <el-table-column min-width="150"
                             header-align="center"
                             label="Actions">
              <div slot-scope="{row}" class="text-center">

                <el-tooltip content="Update branch"
                            :open-delay="300"
                            placement="top">
                  <base-button type="info" size="" @click="showBranchUpdateModel(row)">
                    <i class="tim-icons icon-cloud-upload-94"></i>
                  </base-button>
                </el-tooltip>

                <el-tooltip content="Delete Branch"
                            :open-delay="300"
                            placement="top">
                  <base-button type="danger" size="" @click="deleteBranch(row)">
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </el-tooltip>

              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>

      <div class="col-md-5">
        <card title="Add a branch">
          <div class="card-body">
            <div class="col-md-12">
              <base-input label="Branch Name"
                          type="text"
                          placeholder="Enter branch name to create" v-model="txtAddBranchName">
              </base-input>
              <base-input label="Branch default open image"
                          type="text"
                          placeholder="Enter the default branch open image url" v-model="txtBranchDefaultCounterOpenImage">
              </base-input>
              <base-input label="Branch default close image"
                          type="text"
                          placeholder="Enter the default branch close image url" v-model="txtBranchDefaultCounterCloseImage">
              </base-input>

            </div>
            <!-- Little formatting hack :\ -->
            <div class="col-md-12 form-group">
              <label>Timezone</label>
              <el-select class="select-danger"
                         placeholder="Select a Timezone"
                         v-model="selects.selectedZone">
                <el-option v-for="option in selects.timeZones"
                           class="select-danger"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                </el-option>
              </el-select>
            </div>
            <div class="col-md-12">
              <base-input label="Longitude"
                          type="test"
                          placeholder="52.040623"
                          v-model="txtLong">
              </base-input>
            </div>
            <div class="col-md-12">
              <base-input label="Latitude"
                          type="text"
                          placeholder="-0.759417"
                          v-model="txtLat">
              </base-input>
            </div>
          </div>
          <base-button class="animation-on-hover" type="primary" block @click="addBranch">Add Branch</base-button>
        </card>
      </div>
    </div>


    <modal :show.sync="modals.showBranchUpdate"
           body-classes="p-0">
      <card type="secondary"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template slot="header">
          <h2 class="modal-title" id="exampleModalLabel">Update branch '{{updateBranchModel.branchName}}'</h2>
        </template>
        <div class="row">
          <base-input label="Branch Name"
                      type="text"
                      class="col-md-12"
                      placeholder="Enter branch name to create" v-model="updateBranchModel.branchName">
          </base-input>
          <base-input label="Branch default open image"
                      type="text"
                      class="col-md-12"
                      placeholder="Enter the default branch open image url" v-model="updateBranchModel.branchDefaultCounterOpenImage">
          </base-input>
          <base-input label="Branch default close image"
                      type="text"
                      class="col-md-12"
                      placeholder="Enter the default branch close image url" v-model="updateBranchModel.branchDefaultCounterCloseImage">
          </base-input>
          <div class="col-md-12">
            <label class="col-md-3">Timezone</label>
            <el-select class="select-danger col-md-9"
                       placeholder="Select a Timezone"
                       v-model="branchUpdateSelects.selectedZone">
              <el-option v-for="option in branchUpdateSelects.timeZones"
                         class="select-danger"
                         :value="option.value"
                         :label="option.label"
                         :key="option.label">
              </el-option>
            </el-select>
          </div>
          <div class="row col-md-12">
            <base-input label="Longitude"
                        type="test"
                        class="col-md-6"
                        placeholder="52.040623" v-model="updateBranchModel.longitude">
            </base-input>
            <base-input label="Latitude"
                        type="text"
                        class="col-md-6"
                        placeholder="-0.759417" v-model="updateBranchModel.latitude">
            </base-input>
          </div>
        </div>
        <template slot="footer">
          <base-button type="primary" round @click="updateBranch">Update branch</base-button>
          <base-button type="warning" round @click="modals.showBranchUpdate = false">Close</base-button>
        </template>
      </card>
    </modal>


  </div>
</template>

<script>
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import {AXIOS} from "../../config/http-commons";
  import {Modal} from 'src/components';

  export default {
    name: "BranchAdmin",
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option, Modal
    },
    data() {
      return {
        selects: {
          selectedZone: "Europe/London",
          timeZones: []
        },
        branchUpdateSelects: {
          selectedZone: "Europe/London",
          timeZones: []
        },
        txtAddBranchName: null,
        txtBranchDefaultCounterOpenImage: null,
        txtBranchDefaultCounterCloseImage: null,
        txtLong: null,
        txtLat: null,
        tableData: [
          {
            branchName: "Loading",
            branchProperties: "",
            clientId: -1,
            createdBy: null,
            creationDate: null,
            endDate: null,
            id: -1,
            lastModifiedBy: null,
            lastModifiedDate: null,
            logicId: -1,
            startDate: null,
            branchDefaultCounterOpenImage: null,
            branchDefaultCounterCloseImage: null,
          }
        ],
        updateBranchModel: {
          branchName: '',
          branchDefaultCounterOpenImage: null,
          branchDefaultCounterCloseImage: null,
        },
        modals: {showBranchUpdate: false}
      }
    },
    methods: {
      addBranch() {
        let branchName = this.txtAddBranchName;
        if (branchName == null || branchName == "") {
          this.$notify({type: 'warning', message: 'Branch Name not valid'});
          return;
        }
        let data = {
          branchName: branchName,

          latitude: this.txtLat,
          longitude: this.txtLong,
          branchDefaultCounterOpenImage: this.txtBranchDefaultCounterOpenImage,
          branchDefaultCounterCloseImage: this.txtBranchDefaultCounterCloseImage,
          branchTimeZone: this.selects.selectedZone,
        };
        AXIOS.post('/rest/branch', data).then(response => this.updateBranchTable());
      },
      deleteBranch(row) {
        let logicID = row.logicId;
        AXIOS.delete('/rest/branch/' + logicID).then(r => this.updateBranchTable());
      },
      updateBranchTable() {
        AXIOS.get('/rest/branch').then(response => {
          this.tableData = response.data;
        });
      },
      updateBranch() {
        this.updateBranchModel.branchTimeZone = this.branchUpdateSelects.selectedZone;
        AXIOS.put('/rest/branch/' + this.updateBranchModel.logicId, this.updateBranchModel).then(r => {
          this.updateBranchTable();
          this.modals.showBranchUpdate = false;
          this.updateBranchModel = {};
        });
      },
      showBranchUpdateModel(row) {
        this.updateBranchModel = row;
        this.modals.showBranchUpdate = true
      }
    },
    created() {
      this.updateBranchTable();
      AXIOS.get('/rest/timezone').then(r => {
        let timeZones = [];
        r.data.forEach(time => {
          timeZones.push({value: time, label: time})
        });
        this.selects.timeZones = timeZones;
        this.branchUpdateSelects.timeZones = timeZones;
      });
    }
  }
</script>

<style scoped>

</style>
