<template>
  <div>
    <card type='card-danger' class="text-center" style="">
      <h1 class="card-title">Create Station Admin</h1>
      <h4 class="card-text">Use this page to manage Create Stations.</h4>
      <h6 class="card-text text-muted">AKA Reception, Info Desk</h6>
    </card>

    <div class="row">
      <div class="col-lg-12">
        <card>
          <h2 class="card-title">Current Branches</h2>
          <el-table :data="tableData.branches">
            <el-table-column min-width="50" type="index"></el-table-column>
            <el-table-column min-width="250" prop="branchName" label="Name">
            </el-table-column>
            <el-table-column min-width="90" prop="logicId"
                             label="Logic Id">
            </el-table-column>

            <el-table-column min-width="150"
                             header-align="center"
                             label="Actions">
              <div slot-scope="{row}" class="text-center">

                <el-tooltip content="Show Create Stations"
                            :open-delay="300"
                            placement="top">
                  <base-button type="info" size="" @click="showForBranch(row)">
                    <i class="tim-icons icon-cloud-upload-94"></i>
                  </base-button>
                </el-tooltip>

              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>

    </div>
    <div class="row" v-if="selectedBranch.branchName">
      <div class="col-md-3">
        <card>
          <h2 class="card-title">Add a Create Station to '{{selectedBranch.branchName}}'</h2>
          <div class="card-body">
            <base-input label="Create Station Name"
                        type="text"
                        placeholder="Enter a name to create. Eg 'Desk 1'" v-model="createCreateStationModel.name">
            </base-input>
            <base-input label="Printer Mac"
                        type="text"
                        placeholder="00:00:00:00:00" v-model="createCreateStationModel.printerMac">
            </base-input>
          </div>
          <base-button class="animation-on-hover" type="primary" block @click="addCreateStation">Add Create Station
          </base-button>
        </card>
      </div>


      <div class="col-lg-9">
        <card>
          <h2 class="card-title">Branch '{{selectedBranch.branchName}}' Create Stations</h2>
          <el-table :data="tableData.CreateStationsForBranch">
            <el-table-column min-width="50" type="index"></el-table-column>
            <el-table-column min-width="200" prop="name" label="Name"></el-table-column>
            <el-table-column min-width="120" prop="printerMac" label="Printer Mac"></el-table-column>
            <el-table-column min-width="90" prop="logicId" label="logic Id"></el-table-column>


            <el-table-column min-width="150"
                             header-align="center"
                             label="Actions">
              <div slot-scope="{row}" class="text-center">

                <el-tooltip content="Delete CreateStation"
                            :open-delay="300"
                            placement="top">
                  <base-button type="danger" size="" @click="deleteCreateStation(row)">
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </el-tooltip>

              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import {AXIOS} from "../../config/http-commons";

  export default {
    name: "BranchAdmin",
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
      return {
        createCreateStationModel: {name: '', printerMac: null},
        selectedBranch: {},
        tableData: {
          branches: [
            {
              branchName: "Loading",
              id: -1,
              logicId: -1,
            }
          ],
          CreateStationsForBranch: []
        }
      }
    },
    methods: {
      updateBranchTable() {
        AXIOS.get('/rest/branch').then(response => {
          this.tableData.branches = response.data;
        });
      },
      deleteCreateStation(row) {
        AXIOS.delete('/rest/createstations/branch/' + this.selectedBranch.logicId + '/createstation/' + row.logicId)
          .then(r => {
            this.showForBranch(this.selectedBranch)
          })
      },
      showForBranch(row) {
        AXIOS.get('/rest/createstations/branch/' + row.logicId).then(r => {
          this.tableData.CreateStationsForBranch = r.data;
          this.selectedBranch = row;
        });

      },
      addCreateStation() {
        let data = {
          name: this.createCreateStationModel.name,
          printerMac: this.createCreateStationModel.printerMac
        };
        AXIOS.post('/rest/createstations/branch/' + this.selectedBranch.logicId, data).then(r => {
          this.showForBranch(this.selectedBranch)
        });
      }
    },
    created() {
      this.updateBranchTable();
    }
  }
</script>

<style scoped>

</style>
