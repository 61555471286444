import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import AXIOS from './config/http-commons'
import MarqueeText from 'vue-marquee-text-component'

import {store} from "./config/CSPropertiesStore";
// router setup
import router from './routes/router';
import i18n from './i18n';
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
// plugin setup
Vue.use(DashboardPlugin);

Vue.component('marquee-text', MarqueeText)
var socket = new SockJS('/websocket');
var stompClient = Stomp.over(socket);

function connect() {
  console.log("Start connection")
  socket = new SockJS('/websocket');
  stompClient = Stomp.over(socket);
  stompClient.connect({}, (frame) => {
    console.log('Connected: ' + frame);

    stompClient.subscribe('/topic/refresh', function (data) {
      window.location.reload(true);
    }, {id: 'refresh'});

  }, () => {
    //on fail
    console.log("Attempting reconnect")
    setTimeout(() => {
      connect();
    }, 5000);
  });
  stompClient.debug = () => {
  };
}

connect();

// TODO reconnect on failure

Vue.use(socket);
Vue.use(stompClient);

Vue.prototype.$socket = socket;
Vue.prototype.$stompClient = stompClient;


router.beforeEach(function (to, from, next) {
  if (to.fullPath === '/login') {
    next();
    return;
  }
  if (!store.getters.auth.isLoggedIn) {
    next({path: 'login'})
  } else {
    let levels = store.getters.auth.authLevels;
    // Check if allowed

    if (levels.indexOf(to.meta.authType) >= 0) {
      next();
      return;
    }
    next({path: 'Home'});
  }
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  render: h => h(App),
  router,
  i18n
});
