<template>
  <div>
    <div>
      <card type='card-danger' class="text-center" style="" title="SMS Lookup">
        <h4 class="card-text">Use this page to search customers phone numbers via our SMS provider</h4>
      </card>
    </div>


    <div class="row">
      <div class="col-md-7">
        <card style="" class="card-primary" title="Phone Lookup">

          <base-input v-model="txtPhoneNumberLookup"
                      label="Phone Number Lookup"
                      type="phone"
                      placeholder="+44">
          </base-input>
          <base-button @click="lookup" block class="animation-on-hover" type="primary">Lookup Number</base-button>

        </card>
      </div>


      <div class="col-md-5">
        <card style="" class="card-primary" title="Results">
          <div>
            <p>Phone Number: {{response.international_format_number}}</p>
            <p>Phone Number formatted: {{response.national_format_number}}</p>
            <p>Country Name: {{response.country_name}}</p>
            <p>Country Prefix: {{response.country_prefix}}</p>
            <p>Network: {{response.current_carrier.name}}</p>
            <p>Lookup outcome: {{response.lookup_outcome_message}}</p>
            <p>Reachable: {{response.reachable}}</p>
            <b><p>Status Message: {{response.status_message}}</p></b>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <card style="" class="card-primary" title="Send Test Message">
          <base-input v-model="txtPhoneNumberTest"
                      label="Send test message"
                      type="phone"
                      placeholder="+44">
          </base-input>
          <base-button @click="testMessage" block class="animation-on-hover" type="warning">Send Test message
          </base-button>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
  import {AXIOS} from "../../config/http-commons";

  export default {
    name: "NexmoLookup",
    methods: {
      lookup() {
        AXIOS.get('/rest/nexmolookup/' + this.txtPhoneNumberLookup).then(r => {
          this.response = r.data;
        }).catch(function (error) {
          this.$notify({type: 'danger', message: 'Failed to contact third party. Please notify administrator'})
        });
      },
      testMessage() {
        AXIOS.get('/rest/testsms/' + this.txtPhoneNumberTest).then(r => {
          let response = r.data;
          let message = 'Message \"' + response.message + '\" sent to \"' + response.phoneNumber + '\"';

          this.$notify({type: 'success', message: message});
          if (response.nexmoResponse.message - id) {
            this.$notify({type: 'success', message: 'Response: ' + response.nexmoResponse.message - id});
          }
        }).catch(function (error) {
          this.$notify({type: 'danger', message: 'Failed to send test message, please check number.'})
        });
      }

    },
    data() {
      return {
        txtPhoneNumberLookup: '',
        txtPhoneNumberTest: '',
        response: {
          country_code: "",
          country_code_iso3: "",
          country_name: "",
          country_prefix: "",
          international_format_number: "",
          lookup_outcome: 0,
          lookup_outcome_message: "",
          current_carrier: {name: ''},
          national_format_number: "",
          country: "",
          ported: "",
          reachable: "",
          remaining_balance: "",
          request_id: "",
          request_price: "",
          status_message: "",
          valid_number: "",
        }
      }
    }
  }
</script>

<style scoped>

</style>
