var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.homePage'),
          icon: 'tim-icons icon-bus-front-12',
          path: '/homePage'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Serving',
          icon: 'tim-icons icon-laptop'
        }}},[(_vm.isAllowedToSee('ROLE_QUICKSERVE'))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.quickServe'), path: '/quickServe' }}}):_vm._e(),(_vm.isAllowedToSee('ROLE_RECEPTION'))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.reception'), path: '/receptionPage' }}}):_vm._e(),(_vm.isAllowedToSee('ROLE_COUNTER'))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.workstation'), path: '/workstationPage' }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.ticketLookup'),
          path: '/ticketLookup'
        }}})],1),(_vm.isAllowedToSee('ROLE_EASYSTATS'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Statistics',
          icon: 'tim-icons icon-chart-pie-36'
        }}},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.statPage'),
          path: '/statPage'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.statBranchPage'),
          path: '/statBranchPage'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.statQueuePage'),
          path: '/statQueuePage'
        }}})],1):_vm._e(),(_vm.isAllowedToSee('ROLE_CALENDAR') && false)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.calendar'),
          icon: 'tim-icons icon-time-alarm',
          path: '/calendar'
        }}}):_vm._e(),(_vm.isAllowedToSee('ROLE_ADMIN'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Admin',
          icon: 'tim-icons icon-molecule-40'
        }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.branchAdmin'), path: '/branchAdmin' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.servicePointAdmin'), path: '/servicePointAdmin' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.createStationAdmin'), path: '/createStationAdmin' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.queueAdmin'), path: '/queueAdmin' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.smsLookup'), path: '/smsLookup' }}})],1):_vm._e(),(_vm.isAllowedToSee('ROLE_SUPERADMIN'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Super Admin',
          icon: 'tim-icons icon-molecule-40'
        }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.applicationAdmin'), path: '/applicationAdmin' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.userAdmin'), path: '/userAdmin' }}})],1):_vm._e()]}}])}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }