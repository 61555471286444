<template>
  <div>
    <div class="">
      <card type='card-danger' class="text-center" style="">
        <h1 class="card-title">Branch Stats</h1>
        <h4 class="card-subtitle">Use this page to quickly look at the applications statistics</h4>
      </card>
    </div>
    <div class="row">
      <!-- Run reort and live stats -->
      <div class="col-lg-6">
        <card title="Run Report" sub-title="Enter dates to run report">
          <div class="card-body">
            <div class="row ">
              <div class="col-md-12">
                <label>Select a branch to run the report</label>
                <branch-select style="width: 100%;" class="el-select-custom" :propergate-globally="false"
                               :include-all="true" @localBranchLogicId="updateLocallySelectBranchId"></branch-select>
              </div>
            </div>
            <div class="row " style="padding-top: 20px;">
              <div class="col-md-6">
                <base-input>
                  <h6>Start</h6>
                  <el-date-picker v-model="report.startDate"
                                  type="date"
                                  placeholder="Start Date"
                                  format="dd-MM-yyyy">
                  </el-date-picker>
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input>
                  <h6>End</h6>
                  <el-date-picker v-model="report.endDate"
                                  type="date"
                                  placeholder="End Date"
                                  format="dd-MM-yyyy">
                  </el-date-picker>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="btn-group col-md-12">
                <base-button style='margin: 0px' @click="runReport" class="animation-on-hover" type="primary" block
                             round>Run Report
                </base-button>
                <base-button style='margin: 0px' @click="runReportAsCSV" class="animation-on-hover" type="info" block
                             round>Download detailed report as CSV
                </base-button>
              </div>
            </div>
          </div>
        </card>
      </div>
      <div class="col-lg-6 col-md-6">
        <card title="All branch stats" sub-title="Only showing today's live data">
          <div class="card-body">
            <h5>Current Active Visits: <b>{{live.api.currentAmount}}</b></h5>
            <h5>Served: <b>{{live.api.amounts.callEventsAmount}}</b></h5>
            <h5>Add And Call Served: <b>{{live.api.amounts.addAndCallEventsAmount}}</b></h5>
            <h5>Appointments for Today: <b>0</b></h5>
          </div>
        </card>
      </div>

      <div class="col-lg-6">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Visits by Date</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-bell-55 text-primary "></i>
            </h3>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              :chart-data="visitsByDateGraph.chartData"
              :gradient-colors="visitsByDateGraph.gradientColors"
              :gradient-stops="visitsByDateGraph.gradientStops"
              :extra-options="visitsByDateGraph.extraOptions"
              ref="visitsByDateGraph"
            >
            </line-chart>
          </div>
        </card>
      </div>
      <div class="col-lg-6">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Add and Call by Events</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-send text-success "></i>
            </h3>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              :chart-data="addAndCallByDatesGraph.chartData"
              :gradient-stops="addAndCallByDatesGraph.gradientStops"
              :extra-options="addAndCallByDatesGraph.extraOptions"
              ref="addAndCallGraph"
            >
            </line-chart>
          </div>
        </card>
      </div>

      <!-- Stats Cards -->
      <div class="col-lg-4 col-md-6">
        <stats-card
          v-bind:title="report.api.amounts.totalVisits.toString()"
          sub-title="Total Visits"
          type="info"
          icon="tim-icons icon-chat-33">
          <div slot="footer"><i class="tim-icons icon-watch-time"></i> In the last {{hoursSinceMidnight}} hours</div>
        </stats-card>
      </div>
      <div class="col-lg-4 col-md-6">
        <stats-card
          v-bind:title="report.api.amounts.totalEventsAmounts.toString()"
          sub-title="Total Events"
          type="primary"
          icon="tim-icons icon-cloud-upload-94">
          <div slot="footer"><i class="tim-icons icon-watch-time"></i> In the last {{hoursSinceMidnight}} hours</div>
        </stats-card>
      </div>
      <div class="col-lg-4 col-md-6">
        <stats-card
          v-bind:title="report.api.amounts.averageWaitTimeHHmmSS"
          sub-title="Avg. Wait Time"
          type="warning"
          icon="tim-icons icon-time-alarm">
          <div slot="footer"><i class="tim-icons icon-watch-time"></i> In the last {{hoursSinceMidnight}} hours</div>
        </stats-card>
      </div>
      <div class="col-lg-4 col-md-6">
        <stats-card
          v-bind:title="report.api.amounts.totalWaitTimeHHmmSS"
          sub-title="Total Wait Time"
          type="warning"
          icon="tim-icons icon-time-alarm">
          <div slot="footer"><i class="tim-icons icon-watch-time"></i> In the last {{hoursSinceMidnight}} hours</div>
        </stats-card>
      </div>
      <div class="col-lg-4 col-md-6">
        <stats-card
          v-bind:title="report.api.amounts.addAndCallEventsAmount.toString()"
          sub-title="Add and Call Events"
          type="danger"
          icon="tim-icons icon-tv-2">
          <div slot="footer"><i class="tim-icons icon-watch-time"></i> In the last {{hoursSinceMidnight}} hours</div>
        </stats-card>
      </div>
      <div class="col-lg-4 col-md-6">
        <stats-card
          v-bind:title="report.api.amounts.callEventsAmount.toString()"
          sub-title="Call Events"
          type="primary"
          icon="tim-icons icon-send">
          <div slot="footer"><i class="tim-icons icon-watch-time"></i> In the last {{hoursSinceMidnight}} hours</div>
        </stats-card>
      </div>

    </div>
  </div>
</template>
<script>
  import LineChart from 'src/components/Charts/LineChart';
  import * as chartConfigs from 'src/components/Charts/config';
  import UserTable from '../../pages/Dashboard/UserTable';
  import StatsCard from 'src/components/Cards/StatsCard';
  import config from 'src/config';
  import {DatePicker} from 'element-ui'
  import {AXIOS} from "../../config/http-commons";
  import BranchSelect from '../../components/custom/BranchSelect'

  export default {
    components: {
      LineChart,
      StatsCard,
      UserTable,
      [DatePicker.name]: DatePicker,
      BranchSelect
    },
    data() {
      return {
        report: {
          startDate: new Date().setMonth(new Date().getMonth() - 1),
          endDate: new Date(),
          api: {
            addAndCalls: [],
            date: [],
            amounts: {
              addAndCallEventsAmount: 0,
              addEventsAmount: 0,
              averageWaitTimeHHmmSS: "00:00:00",
              callEventsAmount: 0,
              totalEventsAmounts: 0,
              totalVisits: 0,
              totalWaitTimeHHmmSS: "00:00:00",
            },
          }
        },
        live: {
          api: {
            amounts: {
              addAndCallEventsAmount: 0,
              addEventsAmount: 0,
              callEventsAmount: 0,
              totalEventsAmounts: 0,
              totalVisits: 0,
              totalWaitTimeHHmmSS: "00:00:00",
            },
            currentAmount: 1
          },
        },
        hoursSinceMidnight: new Date().getHours(),
        visitsByDateGraph: {
          extraOptions: chartConfigs.purpleChartOptions,
          chartData: {
            labels: [this.formatDateForPreview(new Date())],
            datasets: [
              {
                label: 'Amount',
                fill: true,
                borderColor: config.colors.primary,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.primary,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: config.colors.primary,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: [0]
              }
            ]
          },
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.2, 0]
        },
        addAndCallByDatesGraph: {
          extraOptions: chartConfigs.greenChartOptions,
          chartData: {
            labels: [this.formatDateForPreview(new Date())],
            datasets: [
              {
                label: 'Amount',
                fill: true,
                borderColor: config.colors.danger,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.danger,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: config.colors.danger,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: [0]
              }
            ]
          },
          gradientColors: [
            'rgba(66,134,121,0.15)',
            'rgba(66,134,121,0.0)',
            'rgba(66,134,121,0)'
          ],
          gradientStops: [1, 0.4, 0]
        },
        subscriptions: [],
        localSelectedBranchId: -1,
      };
    },
    methods: {
      runReport() {
        let startDate = this.formatDate(this.report.startDate);
        let endDate = this.formatDate(this.report.endDate);
        if (this.localSelectedBranchId === -1) {
          this.$notify({type: 'warning', message: 'Select a branch or choose all'})
          return;
        }
        if (this.localSelectedBranchId == -2) {
          AXIOS.get('/rest/stat/data/start/' + startDate + "/end/" + endDate).then(response => {
            this.report.api = response.data;
            this.fillOutReport();
          });
          return
        }
        AXIOS.get('/rest/stat/data/branch/' + this.localSelectedBranchId + '/start/' + startDate + "/end/" + endDate).then(response => {
          this.report.api = response.data;
          this.fillOutReport();
        });
      },
      fillOutReport() {


        let listOfDatesAndValues = this.report.api.addAndCalls;
        let orderedEvents = {};
        Object.keys(listOfDatesAndValues).sort().forEach(function (key) {
          orderedEvents[key] = listOfDatesAndValues[key];
        });

        let labels = Object.keys(orderedEvents);
        let values = Object.values(orderedEvents);
        this.addAndCallByDatesGraph.chartData.labels = labels;
        this.addAndCallByDatesGraph.chartData.datasets[0].data = values;

        listOfDatesAndValues = this.report.api.date;
        orderedEvents = {};
        Object.keys(listOfDatesAndValues).sort().forEach(function (key) {
          orderedEvents[key] = listOfDatesAndValues[key];
        });
        labels = Object.keys(orderedEvents);
        values = Object.values(orderedEvents);
        this.visitsByDateGraph.chartData.labels = labels;
        this.visitsByDateGraph.chartData.datasets[0].data = values;

        this.$refs.addAndCallGraph.onDemandRender(this.addAndCallByDatesGraph.chartData)
        this.$refs.visitsByDateGraph.onDemandRender(this.visitsByDateGraph.chartData)
        this.$notify({type: 'success', message: 'Report ran successfully'})
      },
      updateLocallySelectBranchId(selectedBranchId) {
        this.localSelectedBranchId = selectedBranchId
      },
      runReportAsCSV() {
        ///rest/stat/raw/csv/start/20180101/end/20191101
        let startDate = this.formatDate(this.report.startDate);
        let endDate = this.formatDate(this.report.endDate);
        if (this.localSelectedBranchId === -1) {
          this.$notify({type: 'warning', message: 'Select a branch or choose all'})
          return;
        }
        if (this.localSelectedBranchId == -2) {
          AXIOS.get('/rest/stat/raw/csv/start/' + startDate + "/end/" + endDate).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'csv report.csv');
            document.body.appendChild(link);
            link.click();
          });
          return
        }
        //LAZY
        AXIOS.get('/rest/stat/raw/branch/' + this.localSelectedBranchId + '/csv/start/' + startDate + "/end/" + endDate).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'csv report.csv');
          document.body.appendChild(link);
          link.click();
        });
      },
      liveDataFill() {
        AXIOS.get("/rest/stat/live/data/").then(response => {
          this.live.api = response.data
        });
      },
      formatDate(date) {
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('');
      },
      formatDateForPreview(date) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('/');
      }
    },
    mounted() {
      this.liveDataFill();

      this.subscriptions.push(this.$stompClient.subscribe('/topic/call', (data) => {
        this.liveDataFill();
      }, {id: 'statCall'}));
      this.subscriptions.push(this.$stompClient.subscribe('/topic/add', (data) => {
        this.liveDataFill();
      }, {id: 'statAdd'}));
      this.subscriptions.push(this.$stompClient.subscribe('/topic/end', (data) => {
        this.liveDataFill();
      }, {id: 'statEnd'}));
    },
    beforeDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe())
    }
  };
</script>
<style scoped>
  .el-select-custom {
    width: 100%;
  }
</style>
