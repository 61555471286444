<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a
            href="http://jchendrick.com/"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            J&C Hendrick Homepage
          </a>
        </li>
        <li class="nav-item">
          <a>Licenced to:  {{$store.getters.csProperties.clientName}}</a>
        </li>
      </ul>

      <div class="copyright">
        {{$store.getters.csProperties.applicationName}} &copy; {{ year }} J&C Hendrick. Version {{$store.getters.csProperties.version}}
      </div>
    </div>
  </footer>
</template>
<script>

  export default {
    data() {
      return {
        year: new Date().getFullYear(),
      };
    },
  };
</script>
<style></style>
