<template>
  <router-view></router-view>
</template>

<script>
  export default {
    methods: {
      initializeLayout() {
        if (!this.$rtl.isRTL) {
          // Just make sure rtl css is off when we are not on rtl
          this.$rtl.disableRTL();
        }
      },

    },
    mounted() {
      this.initializeLayout();
      let b = window.localStorage.getItem('lightMode') === 'true'
      if (b)
        document.body.classList.toggle('white-content');
      this.$store.dispatch('loadProperties'); //This is syncronized. Woo hoo!
    }
  };
</script>
