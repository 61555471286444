<template>
  <div>
    <card class="text-center">
      <h1 class="card-title">Application Admin</h1>
      <h4 class="card-subtitle">Use this page to check the application settings</h4>
    </card>
    <div class="row">
      <div class="col-md-6">
        <card title="Application client properties">
          <h5 class="card-subtitle text-muted">Does not show secret values</h5>
          <div class="card-body">
            <el-table :data="propertiesTable">
              <el-table-column min-width="50" type="index"></el-table-column>
              <el-table-column min-width="150" prop="key" label="Name"></el-table-column>
              <el-table-column min-width="150" prop="value" label="Value"></el-table-column>
            </el-table>
          </div>
        </card>
      </div>

      <div class="col-md-6">
        <card title="Admin controls">
          <div class="card-body row col-md-12 btn-group-vertical">
            <base-button block round type="danger" style="margin:0" class="animation-on-hover"
                         @click="refreshScreensAndDB">Refresh Screens and DB values
            </base-button>
            <base-button block round type="danger" style="margin:0" class="animation-on-hover"
                         @click="updateCurrentTable">Update All Visits table
            </base-button>
            <base-button block round type="danger" style="margin:0" class="animation-on-hover" @click="clearCache">Clear
              Server Caches
            </base-button>
          </div>
        </card>
        <card title="All visits">
          <h4 class="card-subtitle text-muted">Across all branches</h4>
          <div class="card-body">
            <el-table :data="currentTableData">
              <el-table-column min-width="50" type="index"></el-table-column>
              <el-table-column min-width="50" prop="id" label="Visit ID"></el-table-column>
              <el-table-column min-width="50" prop="ticket" label="Ticket"></el-table-column>
              <el-table-column min-width="50" prop="branchLogicId" label="Branch Id"></el-table-column>
            </el-table>
          </div>
        </card>
      </div>
    </div>


  </div>
</template>

<script>
  import {Table, TableColumn} from 'element-ui'
  import {AXIOS} from "../../config/http-commons";

  export default {
    name: "ApplicationAdmin",
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        propertiesTable: [
          {key: 'Loading', value: 'Loading'}
        ],
        currentTableData: [{id: 1, ticket: 'loading'}]
      }
    },
    methods: {
      updatePropertiesTable() {
        AXIOS.get('/rest/settings/getProperties').then(response => {
          let dataArray = [];
          let data = response.data;

          data.forEach(prop => {
            dataArray.push({key: prop.keyName, value: prop.value})
          });

          this.propertiesTable = dataArray;
        })
      },
      updateCurrentTable() {
        AXIOS.get('/rest/settings/current').then(response => {
          this.currentTableData = response.data;
        })
      },
      refreshScreensAndDB() {
        AXIOS.get('/rest/settings/refresh').then(r => {
          let data = r.data;
          this.$notify({type: 'danger', message: 'Licencing properties update: ' + data.licencingUpdated});
          this.$notify({type: 'danger', message: 'Refresh Sent: ' + data.refresh});
          this.$notify({type: 'danger', message: 'Refresh Event Sent: ' + data.refreshEventSent});
          this.updatePropertiesTable();
          this.updateCurrentTable();
        })
      },
      clearCache() {
        AXIOS.get('/rest/settings/clearCaches').then(r => {
          this.$notify({type: 'danger', message: 'Caches cleared'})
          this.updatePropertiesTable();
          this.updateCurrentTable();
        })
      }
    },
    created() {
      this.updatePropertiesTable();
      this.updateCurrentTable();
    } 
  }
</script>

<style scoped>

</style>
