<template>
  <div>
    <card type='card-danger' class="text-center" style="">
      <h1 class="card-title">Service Point Admin</h1>
      <h4 class="card-text">Use this page to manage Service Points.</h4>
      <h6 class="card-text text-muted">AKA Desks, Booths, InfoDesk etc etc</h6>
    </card>

    <div class="row">
      <div class="col-lg-12">
        <card title="Current Branches">
          <el-table :data="tableData.branches">
            <el-table-column min-width="50" type="index"></el-table-column>
            <el-table-column min-width="250" prop="branchName" label="Name">
            </el-table-column>
            <el-table-column min-width="90" prop="logicId"
                             label="Logic Id">
            </el-table-column>

            <el-table-column min-width="150"
                             header-align="center"
                             label="Actions">
              <div slot-scope="{row}" class="text-center">

                <el-tooltip content="Show Service Points"
                            :open-delay="300"
                            placement="top">
                  <base-button type="info" size="" @click="showForBranch(row)">
                    <i class="tim-icons icon-cloud-upload-94"></i>
                  </base-button>
                </el-tooltip>

              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>

    </div>
    <div class="row" v-if="selectedBranch.branchName">
      <div class="col-md-3">
        <card>
          <h2 class="card-title">Add a Service Point to '{{selectedBranch.branchName}}'</h2>
          <div class="card-body">
            <base-input label="Service Point Name"
                        type="text"
                        placeholder="Enter a name to create. Eg 'Desk 1'" v-model="createCallStationModel.name">
            </base-input>
            <base-input label="Service Point Banner Message"
                        type="text"
                        placeholder="Enter a Service Point banner message. Eg 'Please wait to be called'" v-model="createCallStationModel.bannerMessage">
            </base-input>
          </div>
          <base-button class="animation-on-hover" type="primary" block @click="addCallStation">Add Service Point
          </base-button>
        </card>
      </div>


      <div class="col-lg-9">
        <card>
          <h2 class="card-title">Branch '{{selectedBranch.branchName}}' Service Points</h2>
          <el-table :data="tableData.callStationsForBranch">
            <el-table-column min-width="50" type="index"></el-table-column>
            <el-table-column min-width="250" prop="name" label="Name"></el-table-column>
            <el-table-column min-width="90" prop="logicId" label="Logic Id">
            </el-table-column>

            <el-table-column min-width="150"
                             header-align="center"
                             label="Actions">
              <div slot-scope="{row}" class="text-center">

                <el-tooltip content="Update Service Point"
                            :open-delay="300"
                            placement="top">
                  <base-button type="info" size="" @click="showCallStationUpdateModel(row)">
                    <i class="tim-icons icon-cloud-upload-94"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip content="Delete Service Point"
                            :open-delay="300"
                            placement="top">
                  <base-button type="danger" size="" @click="deleteCallStation(row)">
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </el-tooltip>

              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>


    <modal :show.sync="modals.showCallStationUpdate"
           body-classes="p-0">
      <card type="secondary"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template slot="header">
          <h2 class="modal-title" id="exampleModalLabel">Update Service Point '{{updateCallStationModel.name}}'</h2>
        </template>
        <div class="row">
          <base-input label="Service Point Name"
                      type="text"
                      class="col-md-12"
                      placeholder="Enter service point name to update" v-model="updateCallStationModel.name">
          </base-input>
          <base-input label="Service Point banner message"
                      type="text"
                      class="col-md-12"
                      placeholder="Enter the Service Point banner message" v-model="updateCallStationModel.bannerMessage">
          </base-input>          
        </div>
        <template slot="footer">
          <base-button type="primary" round @click="updateCallStation">Update Service Point</base-button>
          <base-button type="warning" round @click="modals.showCallStationUpdate = false">Close</base-button>
        </template>
      </card>
    </modal>



  </div>
</template>

<script>
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import {AXIOS} from "../../config/http-commons";

  export default {
    name: "BranchAdmin",
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
      return {
        createCallStationModel: {name: '', bannerMessage: ''},
        selectedBranch: {},
        tableData: {
          branches: [
            {
              branchName: "Loading",
              id: -1,
              logicId: -1,
            }
          ],
          callStationsForBranch: []
        },
        updateCallStationModel: {
          name: '',
          bannerMessage: '',
          branchLogicId: -1,
          logicId: -1,
          Id: -1,
          clientId: -1
        },
        modals: {showCallStationUpdate: false}
      }
    },
    methods: {
      updateBranchTable() {
        AXIOS.get('/rest/branch').then(response => {
          this.tableData.branches = response.data;
        });
      },
      deleteCallStation(row) {
        AXIOS.delete('/rest/callstations/branch/' + this.selectedBranch.logicId + '/callstation/' + row.logicId)
          .then (r => {
            this.showForBranch(this.selectedBranch)
          })
      },
      showForBranch(row) {
        AXIOS.get('/rest/callstations/branch/' + row.logicId).then(r => {
          this.tableData.callStationsForBranch = r.data;
          this.selectedBranch = row;
        });
      },
      updateCallStationTable() {
        AXIOS.get('/rest/branch').then(response => {
          this.tableData.branches = response.data;
        });
      },
      updateCallStation() {
        AXIOS.put('/rest/callstations/branch/' + this.selectedBranch.logicId + '/callstation/' + this.updateCallStationModel.logicId, this.updateCallStationModel).then(r => {
          this.updateCallStationTable;
          this.modals.showCallStationUpdate = false;
          this.updateCallStationModel = {};
        });
      },
      showCallStationUpdateModel(row) {
        console.log('called showCallStationUpdateModel row')
        console.log(row)
        this.updateCallStationModel = row;
        this.updateCallStationModel.branchLogicId = this.selectedBranch.logicId
        this.updateCallStationModel.clientId = this.selectedBranch.clientId
        


        this.modals.showCallStationUpdate = true
      },
      addCallStation() {
        AXIOS.post('/rest/callstations/branch/' + this.selectedBranch.logicId, {name: this.createCallStationModel.name, bannerMessage: this.createCallStationModel.bannerMessage}).then(r => {
          this.showForBranch(this.selectedBranch)
        });
      }
    },
    created() {
      this.updateBranchTable();
    }
  }
</script>

<style scoped>

</style>
