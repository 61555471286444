<template>
  <div>
    <card class="text-center">
      <h1 class="card-title">Home Page</h1>
      <h2 class="card-subtitle">Welcome to <b>{{$store.getters.csProperties.applicationName}}. </b></h2>
      <div class="card-body">
        <h4>
          <p>Please use the side bar to navigate around the application</p>
          <p>Welcome <b>{{$store.getters.auth.username}}</b> you have no new notifications</p>
          <base-button style="margin-top: 20px;" type="primary" class="animation-on-hover" size="lg" round
                       @click="showPopup" v-if="showBranchSelectButton">Select a Branch
          </base-button>
        </h4>
      </div>

    </card>

    <modal :show.sync="modals.branchSelect"
           body-classes=""
           modal-classes="modal-lg"
           :showClose="false">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template>
          <div class="text-muted text-center mb-3">
            <h3>Please select a branch to continue</h3>
          </div>
          <div class="btn-wrapper text-center">
            <branch-select @click="showPopup"></branch-select>
          </div>
        </template>
      </card>
    </modal>
  </div>

</template>

<script>
  import {AXIOS} from '../../config/http-commons';
  import {Modal} from 'src/components'
  import BranchSelect from '../../components/custom/BranchSelect'
  import {EventBus} from "../../config/Events";


  export default {
    name: "HomePage",
    components: {Modal, BranchSelect},
    data: function () {
      return {
        defaultBranch: {logicId: null, latitude: "52.040623", longitude: "-0.75941"},
        branch: {logicId: null, latitude: "52.040623", longitude: "-0.75941"},
        modals: {
          branchSelect: false
        }
      }
    },
    computed: {
      forceBranchSelect() {
        return this.$store.getters.csProperties['homeScreen.forceBranchSelect'] === 'true';
      },
      showBranchSelectButton() {
        let branchID = this.$store.getters.getBranchID;
        return this.forceBranchSelect && branchID == -1;
      }
    },
    methods: {
      getBranch: function (branchId) {
        console.debug('getBranch called');
        if (this.branch.logicId !== branchId) {
          AXIOS.get('/rest/branch/' + branchId).then(r => this.branch = r.data)
        }
      },
      showPopup: function () {
        this.modals.branchSelect = true
      }
    },
    created() {
      this.modals.branchSelect = this.showBranchSelectButton;
      EventBus.$on('selectBranchLogicId', selectBranchLogicId => {
        if (selectBranchLogicId >= 1)
          this.modals.branchSelect = false;
      });
    }
  }
</script>

<style scoped>

</style>
