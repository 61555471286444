<template>
  <div>
    <el-select class="select-primary"
               style="width: 100%"
               placeholder="Select a branch"
               v-model="branches.selected"
               v-on:change="emitSelectedBranchLogicId">
      <el-option v-if="includeAll" class="select-info" value=-2 label="All branches" key=-2></el-option>
      <el-option v-else class="select-warning" value=-1 label="Please select a branch" key=-1></el-option>
      <el-option v-for="option in branches.api"
                 class="select-info"
                 :value="option.logicId"
                 :label="option.branchName"
                 :key="option.logicId">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  import {AXIOS} from '../../config/http-commons'
  import {Option, Select} from 'element-ui'
  import {EventBus} from "../../config/Events";

  export default {
    name: "BranchSelect",
    data() {
      return {
        branches: {selected: '', api: []},
        branchSelected: -1,
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    props: {
      propergateGlobally: {type: Boolean, required: false, default: true},
      includeAll: {type: Boolean, required: false, default: false},
      
    },
    created() {
      console.debug('created called: getBranch');
      AXIOS.get('/rest/branch').then((response) => {
        this.branches.api = response.data;
        
      });
      console.info('getBranches:');
      console.info(this.branches.api);
      
      if (this.propergateGlobally) {
        EventBus.$on('selectBranchLogicId', selectBranchLogicId => {
          this.branches.selected = selectBranchLogicId;
        });
      }
    },
    methods: {
      emitSelectedBranchLogicId: function(selectedLogicId) {
        console.log('emitNewBranchSelected new Branch Selected:'+ this)
        this.branchSelected = selectedLogicId
        selectedLogicId = Number(selectedLogicId);
        if (this.propergateGlobally) {
          console.log('emitNewBranchSelected globally')
          EventBus.$emit('selectBranchLogicId', selectedLogicId);
          this.$store.dispatch("setSelectedBranchId", selectedLogicId);
        } else {
          console.log('emitNewBranchSelected locally')
          this.$emit('localBranchLogicId', selectedLogicId)
        }
      }
    }
  }
</script>

<style scoped>

</style>
