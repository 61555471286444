<template>
  <div>

    <el-select class="select-primary"
               style="width: 100%"
               placeholder="Select a reason"
               v-model="reasons.selected"
               v-on:change="selectReason">
      <el-option class="select-warning" value=-2 label="Please select a reason" key=-2></el-option>
      <el-option v-for="option in reasons.api"
                 class="select-info"
                 :value="option.id"
                 :label="option.reason"
                 :key="option.id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  import {AXIOS} from '../../config/http-commons'
  import {Option, Select} from 'element-ui'

  export default {
    name: "ServicePointCloseReasonSelect",
    data() {
      return {
        reasons: {selected: '', api: []}
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    methods: {
      selectReason(newReason) {
        this.$emit('reasonSelected', newReason)
      }
    },
    created() {
      AXIOS.get('/rest/callstations/session/reasons')
        .then((response) => {
          this.reasons.api = response.data;
        });
    }
  }
</script>

<style scoped>

</style>
