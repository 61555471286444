<template>
  <div>
    <card type='card-danger' class="text-center" style="" title="Queue Admin">
      <h4 class="card-text">Use this page to globally manage queues and branch queues</h4>
    </card>
    <div class="row">
      <div class="col-md-7">
        <card title="All Queues">
          <div class="card-body">
            <el-table :data="allQueues.tableData">
              <el-table-column min-width="50" type="index"></el-table-column>
              <el-table-column min-width="250" prop="name" label="Name"></el-table-column>
              <el-table-column min-width="100" prop="logicId" label="Queue Id"></el-table-column>
              <el-table-column min-width="250"
                               header-align="center"
                               label="Actions"
                               class="text-center">
                <div slot-scope="{row}" class="text-centre ">
                  <el-tooltip content="Update Queue"
                              :open-delay="300"
                              placement="top">
                    <base-button type="info" size="" @click="showUpdateQueueModal(row)">
                      <i class="tim-icons icon-cloud-upload-94"></i>
                    </base-button>
                  </el-tooltip>

                  <el-tooltip content="Delete Queue"
                              :open-delay="300"
                              placement="top"
                              v-if="row.logicId != -1">
                    <base-button type="danger" size="" @click="deleteQueue(row)">
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </el-tooltip>

                </div>
              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>
      <div class="col-md-5">
        <card title="Add Global Queues">
          <div class="card-body">
            <base-input label="Queue Name"
                        type="text"
                        placeholder="Enter queue name to create" v-model="queueCreate.txtAddQueueName">
            </base-input>
            <div class="row">
              <base-input class="col-sm-4"
                          label="Min ticket number"
                          type="num"
                          placeholder="min ticket number" v-model="queueCreate.minTicket">
              </base-input>
              <base-input class="col-sm-4"
                          label="Max ticket number"
                          type="num"
                          placeholder="Max ticket number" v-model="queueCreate.maxTicket">
              </base-input>
              <base-input class="col-sm-4"
                          label="Ticket Letter"
                          type="text"
                          placeholder="Letter Prefix" v-model="queueCreate.letterPrefix">
              </base-input>
              <base-input class="col-sm-12"
                          label="Ticket Design URL"
                          type="text"
                          placeholder="http://example.com/inputfile.stm" v-model="queueCreate.ticketBaseUrl">
              </base-input>
            </div>

            <code class="text-muted">You can use certain tokens to replace text in message. Such as '{ticket}' for the
              ticket number, and '{name}' for first name.</code>

            <tabs style="padding-top: 20px" type="primary">
              <tab-pane label="SMS">
                <base-input label="SMS Create"
                            type="text"
                            placeholder="Text for visit create" v-model="queueCreate.txtSmsCreate">
                </base-input>
                <base-input label="SMS Call"
                            type="text"
                            placeholder="Text for visit call" v-model="queueCreate.txtSmsCall">
                </base-input>
                <base-input label="SMS End"
                            type="text"
                            placeholder="Text for visit end" v-model="queueCreate.txtSmsEnd">
                </base-input>
              </tab-pane>

              <tab-pane label="Email">
                <p class="text-muted">For example use <a href="https://wordtohtml.net" target="_blank">https://wordtohtml.net</a>
                  to
                  convert text to html</p>
                <base-input label="HTML for Create"
                            disabled
                            type="text"
                            placeholder="HTML for visit create" v-model="queueCreate.txtEmailCreate">
                </base-input>
                <base-input label="HTML for  Call"
                            disabled
                            type="text"
                            placeholder="HTML for visit call" v-model="queueCreate.txtEmailCall">
                </base-input>
                <base-input label="HTML for  End"
                            disabled
                            type="text"
                            placeholder="HTML for visit end" v-model="queueCreate.txtEmailEnd">
                </base-input>
              </tab-pane>
              <tab-pane label="Banner Message">
                <base-input label="Message to Display"
                            type="text"
                            placeholder="Banner message to display for this queue" v-model="queueCreate.txtBannerMessage">
                </base-input>
              </tab-pane>
            </tabs>

            <base-button class="animation-on-hover" type="primary" block @click="addQueue">Add Queue</base-button>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <card class="bg-info" title="Queue Branch Admin">
        <h4 class="card-text"> Use this panel to associate a queue to a branch</h4>
        <div class="row">
          <div class="col-md-12">
            <card title="All Branches">
              <div class="card-body">
                <el-table :data="branch.tableData">
                  <el-table-column min-width="50" type="index"></el-table-column>
                  <el-table-column min-width="170" prop="branchName" label="Name"></el-table-column>
                  <el-table-column min-width="100" prop="logicId" label="Branch ID">
                  </el-table-column>

                  <el-table-column min-width="350"
                                   header-align="center"
                                   label="Associate Queue to branch">
                    <div slot-scope="{row}" class="text-center">
                      <el-select class="select-info"
                                 placeholder="Select a queue"
                                 v-model="row.selectedQueueId">
                        <el-option class="select-warning" value="null" label="Select a Queue" key="null"></el-option>
                        <el-option v-for="option in allQueues.tableData"
                                   class="select-info"
                                   :value="option.id"
                                   :label="option.name"
                                   :key="option.id">
                        </el-option>
                      </el-select>
                      <base-button type="primary" size="sm" @click="saveQueueForBranch(row)">
                        Save
                      </base-button>
                    </div>
                  </el-table-column>


                  <el-table-column min-width="150"
                                   header-align="center"
                                   label="Actions">
                    <div slot-scope="{row}" class="text-center">

                      <el-tooltip content="Show"
                                  :open-delay="300"
                                  placement="top">
                        <base-button type="info" size="sm" @click="showQueuesForBranch(row)">
                          Show Queues
                        </base-button>
                      </el-tooltip>
                    </div>
                  </el-table-column>
                </el-table>
              </div>
            </card>
          </div>


        </div>
        <div class="row">
          <div class="col-md-12">
            <card title="Branch Queues" :sub-title="branchQueues.selectedName">
              <div class="card-body">
                <el-table :data="branchQueues.tableData">
                  <el-table-column min-width="50" type="index"></el-table-column>
                  <el-table-column min-width="250" prop="name" label="Name"></el-table-column>
                  <el-table-column min-width="100" prop="logicId" label="Queue Id"></el-table-column>
                  <el-table-column min-width="100" prop="name" label="Remove Queue from Branch">
                    <div slot-scope="{row}" class="text-center">
                        <base-button type="danger" size="" @click="deleteQueueFromBranch(row)">
                          <i class="tim-icons icon-simple-remove"></i>
                        </base-button>
                    </div>
                  </el-table-column>
                  
                </el-table>
              </div>
            </card>
          </div>
        </div>
      </card>
    </div>


    <modal :show.sync="modals.showUpdateQueue"
           body-classes="p-0">
      <card type="secondary"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template slot="header">
          <h2 class="modal-title" id="exampleModalLabel">Update queue {{updateQueueModal.name}} </h2>
        </template>
        <div>
          <!-- MAJOR TODO Turn add queue into component and pull in here -->

          <div class="card-body">
            <base-input label="Queue Name"
                        type="text"
                        placeholder="Enter queue name to create" v-model="updateQueueModal.name">
            </base-input>
            <div class="row">
              <base-input class="col-sm-4"
                          label="Min ticket number"
                          type="num"
                          placeholder="min ticket number" v-model="updateQueueModal.minTicket">
              </base-input>
              <base-input class="col-sm-4"
                          label="Max ticket number"
                          type="num"
                          placeholder="Max ticket number" v-model="updateQueueModal.maxTicket">
              </base-input>
              <base-input class="col-sm-4"
                          label="Ticket Letter"
                          type="text"
                          placeholder="Letter Prefix" v-model="updateQueueModal.letterPrefix">
              </base-input>
              <base-input class="col-sm-12"
                          label="Ticket Design URL"
                          type="text"
                          placeholder="http://example.com/inputfile.stm" v-model="updateQueueModal.ticketBaseUrl">
              </base-input>
            </div>

            <code class="">You can use certain tokens to replace text in message. Such as '{ticket}' for the
              ticket number, and '{name}' for first name.</code>

            <tabs style="padding-top: 20px" type="primary">
              <tab-pane label="SMS">
                <base-input label="SMS Create"
                            type="text"
                            placeholder="Text for visit create" v-model="updateQueueModal.smsCreateMessage">
                </base-input>
                <base-input label="SMS Call"
                            type="text"
                            placeholder="Text for visit call" v-model="updateQueueModal.smsCallMessage">
                </base-input>
                <base-input label="SMS End"
                            type="text"
                            placeholder="Text for visit end" v-model="updateQueueModal.smsEndMessage">
                </base-input>
              </tab-pane>

              <tab-pane label="Email">
                <p class="text-muted">For example use <a href="https://wordtohtml.net" target="_blank">https://wordtohtml.net</a>
                  to
                  convert text to html</p>
                <base-input label="HTML for Create"
                            disabled
                            type="text"
                            placeholder="HTML for visit create" v-model="updateQueueModal.emailCreateHTML">
                </base-input>
                <base-input label="HTML for  Call"
                            disabled
                            type="text"
                            placeholder="HTML for visit call" v-model="updateQueueModal.emailCallHTML">
                </base-input>
                <base-input label="HTML for  End"
                            disabled
                            type="text"
                            placeholder="HTML for visit end" v-model="updateQueueModal.emailEndHTML">
                </base-input>
              </tab-pane>


              <tab-pane label="Banner Message">
                <base-input label="Message to Display"
                            type="text"
                            placeholder="Banner message to display for this queue" v-model="updateQueueModal.bannerMessage">
                </base-input>
              </tab-pane>

            </tabs>
          </div>
        </div>
        <template slot="footer">
          <base-button type="primary" round @click="updateQueue">Save changes</base-button>
          <base-button type="warning" round @click="modals.showUpdateQueue = false">Close</base-button>
        </template>
      </card>
    </modal>


  </div>
</template>

<script>
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import {AXIOS} from "../../config/http-commons";
  import {Modal, TabPane, Tabs} from 'src/components';

  export default {
    name: "QueueAdmin",
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      TabPane,
      Tabs,
      Modal

    },
    data() {
      return {
        queueCreate: {
          txtAddQueueName: null,
          maxTicket: 500,
          minTicket: 1,
          txtSmsCreate: '',
          txtSmsCall: '',
          txtSmsEnd: '',
          txtEmailCreate: '',
          txtEmailCall: '',
          txtEmailEnd: '',
          txtBannerMessage: '',
          letterPrefix: '',
          ticketBaseUrl: ''
        },
        branch: {
          tableData: [
            {
              branchName: "Loading",
              branchProperties: "",
              clientId: -1,
              createdBy: null,
              creationDate: null,
              endDate: null,
              id: -1,
              lastModifiedBy: null,
              lastModifiedDate: null,
              logicId: -1,
              startDate: null,
              queues: [{
                logicId: -1,
                name: ''
              }]
            }
          ]
        },
        allQueues: {
          tableData: [{
            id: -1,
            name: "Loading",
            startDate: null,
            endDate: null,
            smsCreateMessage: null,
            smsCallMessage: null,
            smsEndMessage: null,
            clientId: -1,
            logicId: -1,
            selectedQueueId: -1,
            emailCreateHTML: null,
            emailCallHTML: null,
            emailEndHTML: null,
            bannerMessage: null,
            ticketBaseUrl: null,
          }]
        },
        branchQueues: {
          selectedName: 'Please select a branch from above',
          selectedlogicId: -1,
          tableData: [{
            id: -1,
            name: "Please load",
            startDate: null,
            endDate: null,
            smsCreateMessage: null,
            smsCallMessage: null,
            smsEndMessage: null,
            emailCreateHTML: null,
            emailCallHTML: null,
            emailEndHTML: null,
            bannerMessage: null,
            clientId: -1,
            ticketBaseUrl: null,
          }]
        },
        pressedValue: false, //TODO as anti spam
        modals: {showUpdateQueue: false},
        updateQueueModal: {
          id: -1,
          name: "Loading",
          startDate: null,
          endDate: null,
          smsCreateMessage: null,
          smsCallMessage: null,
          smsEndMessage: null,
          clientId: -1,
          logicId: -1,
          selectedQueueId: -1,
          emailCreateHTML: null,
          emailCallHTML: null,
          emailEndHTML: null,
          bannerMessage: null,
          letterPrefix: null,
          minTicket: null,
          maxTicket: null,
          ticketBaseUrl: null,
        },
      }
    },
    methods: {
      updateTables() {
        AXIOS.get('/rest/branch').then(response => {
          this.branch.tableData = response.data;
        });
        AXIOS.get('/rest/queues/').then(response => {
          this.allQueues.tableData = response.data;
          this.allQueues.tableData.forEach(q => {
            q.selectedQueueId = -1;
          })
        });
      },
      showUpdateQueueModal(row) {
        this.modals.showUpdateQueue = true
        this.updateQueueModal = row;
      },
      updateQueue() {
        let queue = this.updateQueueModal;
        AXIOS.put('/rest/queues/' + queue.logicId, queue).then(r => {

          this.$notify({type: 'success', message: 'Queue Updated'});
          this.updateTables();
          this.modals.showUpdateQueue = false;
          this.updateQueueModal = {};
        }).catch(err => {
          this.$notify({type: 'danger', message: 'Please contact administrator'});
        });


      },
      deleteQueue(row) {
        let logicId = row.logicId;
        AXIOS.delete('/rest/queues/' + logicId).then(r => {
          this.updateTables();
        }).catch(err => {
          this.$notify({type: 'danger', message: 'Error'});
        });
      },
      addQueue() {
        if (this.queueCreate.txtAddQueueName === '' || this.queueCreate.txtAddQueueName == null) {
          this.$notify({type: 'warning', message: 'Please enter a queue name'});
          return;
        }

        let json = {
          name: this.queueCreate.txtAddQueueName,
          smsCreateMessage: this.queueCreate.txtSmsCreate,
          smsCallMessage: this.queueCreate.txtSmsCall,
          smsEndMessage: this.queueCreate.txtSmsEnd,
          minTicket: this.queueCreate.minTicket,
          maxTicket: this.queueCreate.maxTicket,
          letterPrefix: this.queueCreate.letterPrefix,
          emailCreateHTML: this.queueCreate.txtEmailCreate,
          emailCallHTML: this.queueCreate.txtEmailCall,
          emailEndHTML: this.queueCreate.txtEmailEnd,
          bannerMessage: this.queueCreate.txtBannerMessage,
          ticketBaseUrl: this.queueCreate.ticketBaseUrl,
        };
        AXIOS.post("/rest/queues/", json).then(response => {
          this.updateTables();
          this.clearGlobalQueueTexts();

        }).catch(error => {
          console.debug('Error adding queue, error:')
          console.debug(error)

          if (error.response.data.errorCodeMessage) {
            this.$notify({type: 'danger', message: error.response.data.errorCodeMessage});
          } else {
            this.$notify({type: 'danger', message: 'Error Creating Queue'});
          }
        });
      },
      showQueuesForBranch(row) {
        AXIOS.get('/rest/branch/' + row.logicId).then(response => {
          this.branchQueues.tableData = response.data.queues;
          this.branchQueues.selectedName = row.branchName;
          this.branchQueues.selectedlogicId = row.logicId;
        });
      },
      deleteQueueFromBranch(row) {
        console.log('Called deleteQueueFromBranch row:')
        console.log(row)
        console.log(this.branch)
        AXIOS.delete('/rest/branch/' + this.branchQueues.selectedlogicId +  '/queue/' + row.logicId).then(response => {
          this.branchQueues.tableData = response.data.queues;
          this.branchQueues.selectedName = row.branchName;
        });
      },
      clearGlobalQueueTexts() {
        this.queueCreate.txtAddQueueName = null;
        this.queueCreate.txtSmsCreate = '';
        this.queueCreate.txtSmsCall = '';
        this.queueCreate.txtSmsEnd = '';
        this.queueCreate.maxTicket = '';
        this.queueCreate.letterPrefix = '';
        this.queueCreate.txtEmailCreate = '';
        this.queueCreate.txtEmailEnd = '';
        this.queueCreate.txtBannerMessage = '';
        this.queueCreate.txtEmailCall = '';
        this.queueCreate.ticketBaseUrl = '';

      },
      saveQueueForBranch(row) {
        let selectedQueueId = row.selectedQueueId;
        let ques = this.allQueues.tableData;
        for (let i = 0; i < ques.length; i++) {
          let que = ques[i];
          if (que.id == selectedQueueId) {
            let body = JSON.stringify(que);
            AXIOS.post('/rest/branch/' + row.logicId + '/queue', body).then(response => {
              this.updateTables();
              this.showQueuesForBranch(row);
            });
            break;
          }
        }
      }
    },
    created() {
      this.updateTables();
    }
  }
</script>

<style scoped>

</style>
